import type { Params } from '@remix-run/react'
import { addBreadcrumb, captureException } from '@sentry/remix'

import type { LiteralOrString } from 'types/utils'
import appRoutes from '~/constants/appRoutes'
import constants from '~/constants/index.server'
import type { RoutePathsKeys } from '~/constants/paths'

const isRoutePath = (v: string): v is keyof typeof appRoutes =>
  Object.keys(appRoutes).includes(v)

export const appendSearchParamsString = (
  url: string,
  searchParams?: string | URLSearchParams,
) => {
  if (!searchParams) return url

  const searchParamsString =
    typeof searchParams === 'string' ? searchParams : searchParams.toString()

  if (searchParamsString === '') return url

  const pathWithParams = `${url}?${searchParamsString}`
  return pathWithParams
}

const isOptionalRegex = (key: string) => new RegExp(`\\(\\$${key}\\)`)

const replaceUrl = (replacementUrl: string, replacements: Params) => {
  const replacementKeys = Object.keys(replacements ?? {})
  if (replacementKeys.length > 0) {
    replacementKeys.forEach(key => {
      const replacementValue = replacements[key]

      if (!replacementValue) return

      if (isOptionalRegex(key).test(replacementUrl)) {
        replacementUrl = replacementUrl.replace(
          `($${key})`,
          replacementValue.toString(),
        )
      } else {
        replacementUrl = replacementUrl.replace(
          `$${key}`,
          replacementValue.toString(),
        )
      }
    })
  }

  // Remove optionals
  replacementUrl = replacementUrl.replaceAll(/\/\(\$.*\)/g, '')

  return replacementUrl
}

/**
 * Basic url check to know if url contains `$`, which means it has
 * segments not fully replaced with their values.
 *
 * @param url the value to check
 * @returns boolean indicating if url has been fully resolved or not
 */
export const isResolvedUrl = (url: string) => !url.includes('/$')

const assertFullReplacement = (
  replacementUrl: string,
  data?: { [key: string]: any },
) => {
  if (!isResolvedUrl(replacementUrl)) {
    addBreadcrumb({
      level: 'log',
      category: 'utils',
      message: 'url utils > assertFullReplacement',
      data: { replacementUrl, data },
    })

    const err = new Error(
      `Url resolver error: Params are not replaced for ${data?.path}`,
    )
    throw err
  }
}

const replaceSearchParams = (
  replacementUrl: string,
  searchParams?: string | URLSearchParams,
) => {
  if (!searchParams) return replacementUrl

  searchParams =
    typeof searchParams === 'string'
      ? new URLSearchParams(searchParams)
      : searchParams

  replacementUrl = appendSearchParamsString(
    replacementUrl,
    searchParams.toString(),
  )

  return replacementUrl
}

// Replaces url dynamic paths with values, for example: /user/$id -> /user/123
export const resolve = (
  path: LiteralOrString<RoutePathsKeys>,
  replacements: Params,
  searchParams?: URLSearchParams | string,
) => {
  let replacementUrl: string = isRoutePath(path) ? appRoutes[path] : path

  replacementUrl = replaceUrl(replacementUrl, replacements)

  assertFullReplacement(replacementUrl, {
    path,
    replacements,
    searchParams,
  })

  replacementUrl = replaceSearchParams(replacementUrl, searchParams)

  return replacementUrl
}

// Replaces url dynamic paths with values, for example: /user/$id/$config -> /user/123/$config,
// however does not throw if one or more dynamic values where not replaced
export const resolvePartial = (
  path: LiteralOrString<RoutePathsKeys>,
  replacements: Params,
  searchParams?: URLSearchParams | string,
) => {
  let replacementUrl: string = isRoutePath(path) ? appRoutes[path] : path

  replacementUrl = replaceUrl(replacementUrl, replacements)
  replacementUrl = replaceSearchParams(replacementUrl, searchParams)

  return replacementUrl
}

/**
 * Replace url dynamic values for multiple routes at once.
 *
 * @param routes an object with route path keys and path replace props
 *
 * @return a object with route keys and their resolved values
 */
export const resolveMap = <const T extends string>(
  routes: Record<
    T,
    [
      path: LiteralOrString<RoutePathsKeys>,
      replacements: Params,
      searchParams?: URLSearchParams | string,
    ]
  >,
) => {
  const keys = Object.keys(routes) as T[]

  let mappedRoutes = {} as Record<T, string>
  keys.forEach(key => {
    const [path, replacements, searchParams] = routes[key]
    mappedRoutes[key] = resolve(path, replacements, searchParams)
  })

  return mappedRoutes
}

const baseUrl =
  ((typeof window !== 'undefined' && window.env?.baseUrl) ||
    constants?.baseUrl) ??
  'https://energialemon.com.br'

export const toFullUrl = (url: URL | string) => {
  if (typeof url !== 'string') return url

  const isPathname = url.startsWith('/')

  return new URL(
    // If we only pass a string, it might come as pathname only or the full url.
    url,
    isPathname ? baseUrl : undefined,
  )
}

export const toUrlSearchParam = (searchParams?: URLSearchParams | string) => {
  if (searchParams === undefined) return new URLSearchParams()
  if (typeof searchParams !== 'string') return searchParams

  return new URLSearchParams(searchParams)
}

export const mergeUrlSearchParams = (
  ...searchParamsItems: Array<URLSearchParams | string | undefined>
) => {
  const normalizedSearchParams = searchParamsItems
    .filter(item => item !== undefined)
    .map(item => (typeof item === 'string' ? new URLSearchParams(item) : item))

  return new URLSearchParams(
    ...[normalizedSearchParams.flatMap(item => [...item])],
  )
}

/**
 * Returns the pathname without the params for the given url. Example:
 *
 *  - url: `/user/123/likes/abc`
 *
 *  - clean path: `/user/$id/likes/$commentId`
 *
 * @param params the loader/action arg params
 * @param url the request url
 * @returns the path without params
 */
export const urlToCleanPath = (params: Params, url: URL | string) => {
  const pathname = toFullUrl(url).pathname

  const keys = Object.keys(appRoutes) as Array<RoutePathsKeys>
  const cleanPath = keys.find(pathKey => {
    try {
      return resolve(pathKey, params) === pathname
    } catch {
      return false
    }
  })

  if (!cleanPath) {
    return pathname
  }

  return appRoutes[cleanPath]
}

export const encodeSearchParam = (v: unknown) => {
  try {
    const stringifiedParams = JSON.stringify(v)
    const encodedParams = Buffer.from(stringifiedParams).toString('base64')

    return encodedParams
  } catch (error) {
    captureException(error)
    return ''
  }
}

export const decodeSearchParam = <T>(encoded: string) => {
  try {
    const decodedData = Buffer.from(encoded, 'base64').toString('utf-8')
    const parsedData = JSON.parse(decodedData) as T

    return parsedData
  } catch (error) {
    addBreadcrumb({
      level: 'error',
      message: 'decodeSearchParam catch',
      data: { error },
    })
    return null
  }
}
